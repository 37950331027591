import React, { useEffect } from 'react';
import { Box, Image, Flex, Text, Wrap, WrapItem, Spinner, VStack, SimpleGrid, Link, Icon } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import Slider, { Settings } from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';

import '@fontsource/work-sans';


import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import section2cropped from '../assets/section2.png';
// import mobileHeaderNotification from '../assets/mobileHeaderNotification.png';
import categoryTitleContainer from '../assets/cateogryBannerDesktop.png';
import mobileCategoryTitleContainer from '../assets/cateogryBannerDesktop.png';
// import desktopFoodBannerES from '../assets/company/COMESTIBLE_ES.png';
// import desktopFoodBannerCN from '../assets/company/COMESTIBLE_CN.png';
// import desktopTechBannerES from '../assets/company/ELECTRONICA_ES.png';
// import desktopTechBannerCN from '../assets/company/ELECTRONICA_CN.png';

// import mobileFoodBannerES from '../assets/company/COMESTIBLE_MOBILE_ES.png';
// import mobileFoodBannerCN from '../assets/company/COMESTIBLE_MOBILE_CN.png';

// import mobileTechBannerES from '../assets/company/ELECTRONICA_MOBILE_ES.png';
// import mobileTechBannerCN from '../assets/company/ELECTRONICA_MOBILE_CN.png';

// import banner_promo2 from '../assets/web_banner_promo2.png';
// import banner_promo3 from '../assets/web_banner_promo3.png';
// import youMaylikeBanner from '../assets/esto_te_puede_gustar.png';
// import mostSalesBanner from '../assets/lo_más_vendido.png';
// import mobile_bazar_banner1 from '../assets/Mobile_BAZAR_1_1.png';
// import mobile_bazar_banner2 from '../assets/Mobile_BAZAR_2_1.png';
import carousel1_es from '../assets/banners/BIENVENIDA_ES.jpg';
import carousel1_cn from '../assets/banners/BIENVENIDA_CN.jpg';
import carousel2_es from '../assets/banners/5_OFF_ES.jpg';
import carousel2_cn from '../assets/banners/5_OFF_CN.jpg';
import carousel3_es from '../assets/banners/RETIRO_GRATIS_ES.jpg';
import carousel3_cn from '../assets/banners/RETIRO_GRATIS_CN.jpg';
import carousel4_es from '../assets/banners/COMPRA_MIN_ES.jpg';
import carousel4_cn from '../assets/banners/COMPRA_MIN_CN.jpg';
import carousel5_es from '../assets/banners/ENVIOS_ES.jpg';
import carousel5_cn from '../assets/banners/ENVIOS_CN.jpg';
import carousel6_es from '../assets/banners/MEDIO_DE_PAGO_ES.jpg';
import carousel6_cn from '../assets/banners/MEDIO_DE_PAGO_CN.jpg';
// import mobileSectionBazar from '../assets/mobileSectionBazar.png';
// import mobileSectionVinos from '../assets/mobileSectionVinos.png';
// import mobileSectionOriental from '../assets/mobileSectionOriental.png';
// import mobileBannerComingSoon from '../assets/mobileBannerComingSoon.png';
// import mobileBannerPromotion from '../assets/mobileBannerPromotion.png';
import { ChevronDownRectangle, ChevronUpRectangle, WhiteCartIcon } from '../assets/icons/iconList';
// import { PhoneIcon } from '@chakra-ui/icons';
// import mpIcon from '../assets/icons/mpIcon.png';
// import wappIcon from '../assets/icons/wappIcon.png';

import { categoriesMenu } from '../variables/categoriesMenu';

import Carousel from '../components/Carousel';
import ListOfProducts from '../components/ListOfProducts';
import NavBar from '../components/NavBar';

import { AllCategories, CurrentFilters, Product } from '../interfaces/interfaces';
import { getDataLocal, saveDataLocal } from '../helpers/LocalStorage';
import { getCategories, getProducts } from '../api/products';
import { getCompanies } from '../api/company';
import { CartContext } from '../contexts/CartContext';
import { useTranslation } from '../contexts/TranslationContext';

// import CustomTooltip from '../components/CustomTooltip';


export default function Home(params: any) {

	const cartContext = React.useContext(CartContext);
	const boxRef = React.useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
	
	const PRODS_PER_PAGE = 300;
	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });
	const [slider, setSlider] = React.useState<Slider | null>(null);

	const [currentCompany, setCurrentCompany] = React.useState<number>(params.companyId);
	const [allCompanies, setAllCompanies] = React.useState<any[]>([]);
	const [isSearching, setIsSearching] = React.useState(false);
	const [originalProducts, setOriginalProducts] = React.useState<Product[]>([]);
	const [products, setProducts] = React.useState<Product[]>([]);
	const [allCategories, setAllCategories] = React.useState<AllCategories>([]);
	// const [groups, setGroups] = React.useState<Group[]>([]);
	// const [categoriesCheckBox, setCategoriesCheckBox] = React.useState<CategoryCheckBox[]>([]);

	const [selectedCategory, setSelectedCategory] = React.useState(categoriesMenu[0]);

	const [isExpanded, setIsExpanded] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isLoadingNewPage, setIsLoadingNewPage] = React.useState(false);
	// const [showMobileFilters, setShowMobileFilters] = React.useState(false);

	const { translations, language } = useTranslation();

	const [currentSearchFilters, setCurrentSearchFilters] = React.useState<CurrentFilters>({
		companyId: params.companyId,
		page: 1,
		productsQty: PRODS_PER_PAGE,
		categories: [],
		promotions: []
	});

	// const [expandFilters, setExpandFilters] = React.useState(false);
	// const [minPrice, setMinPrice] = React.useState(-1);
	// const [maxPrice, setMaxPrice] = React.useState(-1);

	const mobileCarouselImagesES = [carousel1_es, carousel2_es, carousel3_es, carousel4_es, carousel5_es, carousel6_es];
	const mobileCarouselImagesCN = [carousel1_cn, carousel2_cn, carousel3_cn, carousel4_cn, carousel5_cn, carousel6_cn];

	const mobileCarouselImages = language === 'spanish' ? mobileCarouselImagesES : mobileCarouselImagesCN;
	
	const settings: Settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	useEffect(() => {
		const intervalo = setInterval(() => {
			slider?.slickNext();
		}, 8000);
		
		return () => clearInterval(intervalo);
	}, [slider]);

	useEffect(() => {
		loadCategories();
		loadCompanies();
		// console.log('transition', translations, language);
		if (boxRef.current) {
			boxRef.current?.scrollIntoView({behavior: 'smooth', block:'end'});
		}
	}, [currentCompany, isSearching]);

	//effect de productos
	useEffect(() => {
		loadProducts();
	}, [currentSearchFilters]);


	useEffect(() => {
		loadCompanies();
		const handleLoad = () => {
			// Código que se ejecuta cuando el componente y sus recursos se han cargado completamente
			cartContext.getUserCart();
		};
		// Agregar el efecto cuando el componente se monta
		window.addEventListener('load', handleLoad);
		// Limpiar el efecto antes de desmontar el componente
		return () => {
			window.removeEventListener('load', handleLoad);
		};
	}, []);



	const observer = React.useRef<IntersectionObserver | null>(null);
	
	const lastProductElementRef = React.useCallback((node: any) => {
		if (isLoading) return;
		if (observer.current) observer.current.disconnect();
		observer.current = new IntersectionObserver(entries => {
			// console.log('Pagina nueva: ',currentSearchFilters.page+1);
			if (entries[0].isIntersecting) {
				setCurrentSearchFilters(prevCurrFilters => { return { ...prevCurrFilters, page: prevCurrFilters.page + 1 }; });
			}
		});
		if (node) observer.current.observe(node);
	}, [isLoading]);

	const loadCompanies = async () => {
		const companies = await getCompanies();
		const updatedData = companies.map((item: { businessName: string; }) => ({
			...item,
			businessName: item.businessName.split(' - ')[1]
		}));
		setAllCompanies(updatedData);
	};

	const loadCategories = async () => {
		const business = await getDataLocal('business');
		// console.log('el companyId es',params.companyId);
		
		const companyIdToUse = business && business.companyID ? parseInt(business.companyID) : params.companyId;
		setCurrentSearchFilters({...currentSearchFilters, companyId: parseInt(companyIdToUse)});
		setCurrentCompany(companyIdToUse);

		const categoriesAux = await getCategories({ companyId: companyIdToUse });

		const categoriesWithID =  categoriesAux.map( (category, index) => {
			const newObj =  { category: category.category, id: index + 1 };
			return newObj;
		});

		setAllCategories((prevAllCategories) => ({ ...prevAllCategories, [companyIdToUse]: categoriesWithID}) );
	};

	const loadProducts = async () => {
		if (isLoading) return;
		if (currentSearchFilters.page == 1) setIsLoading(true); else setIsLoadingNewPage(true);

		const business = await getDataLocal('business');
		let newProducts;
		if (business && business.companyID) newProducts = await getProducts({...currentSearchFilters, companyId: parseInt(business.companyID)});
		else newProducts = await getProducts(currentSearchFilters);
		
		setProducts([...products, ...newProducts]);
		setOriginalProducts([...products, ...newProducts]);
		if (currentSearchFilters.page == 1) setIsLoading(false); else setIsLoadingNewPage(false);
	};

	const updateCurrentCompany = (newCompanyId: number) => {
		setCurrentCompany(newCompanyId);
		deleteCurrentProducts();
		setCurrentSearchFilters({ productsQty: PRODS_PER_PAGE, companyId: newCompanyId, page: 1, categories: [], promotions: [] });
		saveDataLocal('business', JSON.stringify({ companyID: newCompanyId.toString() }));
	};
	
	function deleteCurrentProducts() {
		setProducts([]);
		setOriginalProducts([]);
	}

	// const filterProductsByGroups = (selectedGroup: string) => {
	// 	deleteCurrentProducts();
	// 	setCurrentSearchFilters({ productsQty: PRODS_PER_PAGE, companyId: 3, promotions: [selectedGroup],  page: 1, categories: [] });
	// };		

	const filterProductsByCategories = async (selectedCategory: any) => {
		setIsLoading(true);
		setSelectedCategory(selectedCategory);

		const pagination = {
			productsQty: PRODS_PER_PAGE,
			page: 1
		};
		
		let filters;
		if (selectedCategory.category.length !== 0) {  // filtro especifico
			filters = { companyId: currentCompany, ...pagination, categories: [ selectedCategory.category == 'Todos' ? '' : selectedCategory.category ], promotions: [] };
		} else { // Caso ALL
			filters = { companyId: currentCompany, ...pagination, categories: [], promotions: [] };
		}
		
		deleteCurrentProducts();
		setCurrentSearchFilters(filters);
		// setCurrentCompany(selectedCategory.id);
		// saveDataLocal('business', JSON.stringify({ companyID: selectedCategory.id.toString() }) );
		setIsLoading(false);
	};

	
	// const filterProductsByModal = () => {

	// 	let filteredProducts = [...originalProducts];

	// 	if (minPrice !== -1) {
	// 		filteredProducts = filteredProducts.filter((product) => {  // FILTRA POR MIN PRICE
	// 			return product.priceWholesale >= minPrice;
	// 		});
	// 	}

	// 	if (maxPrice !== -1) {
	// 		filteredProducts = filteredProducts.filter((product) => {  // FILTRA POR MIN PRICE
	// 			return product.priceWholesale <= maxPrice;
	// 		});
	// 	}

	// 	categoriesCheckBox.map(checkbox => {
	// 		if (checkbox.isChecked) {
	// 			filteredProducts = filteredProducts.filter((product) => {  // FILTRA POR CATEGORIA
	// 				return product.category === checkbox.label;
	// 			});
	// 		}
	// 		return null;
	// 	});

	// 	setProducts(filteredProducts);
	// };


	const filterByTextSearch = async (search: string) => {
		const filters = {
			companyId: currentSearchFilters.companyId,
			page: 1,
			productsQty: PRODS_PER_PAGE,
			categories: [],
			search,
			promotions: []
		};
		// actualizo el estado para el autoscroller
		setIsSearching(!isSearching);

		deleteCurrentProducts();
		setCurrentSearchFilters(filters);
	};


	const mobileFront = () => {
		return (
			<Box pt={20} paddingBottom={'30%'} backgroundColor={'white'} alignSelf={'center'} justifyContent={'center'}>
				{/* <VStack spacing={0.3} pb={3}>
					<Flex flexDirection={'row'} alignItems={'center'}>
						<PhoneIcon mr='1' w={'13px'} h={'13px'}/>
						<Text fontWeight={'bold'} fontSize={14}>4587-2557 / 11-2717-1142</Text>
					</Flex>
					<Flex flexDirection={'row'} alignItems={'center'}>
						<Image src={wappIcon} w={'2.5vh'} mr={'0'}/>
						<Text fontWeight={'bold'} fontSize={14}>+54 911 2717 1142</Text>
					</Flex>
					<Flex flexDirection={'row'}>
						<Image src={mpIcon} w={'2.5vh'} mr={'2'}/>
						<Text fontWeight={'bold'} fontSize={12} mr='2'>Mercadopago电子邮箱:</Text>
						<Text fontSize={12}>wgygw1@gmail.com</Text>
					</Flex>
				</VStack> */}
				{/* <Image src={mobileHeaderNotification} pb={'5%'}/> */}
				<Box
					position="fixed"
					bottom="12vh" // Espaciado desde la parte inferior
					right="4" // Espaciado desde la derecha
					zIndex="1000" // Asegura que esté por encima de otros elementos
				>
					<Link
						href="https://wa.me/5491165361357" // Tu número de WhatsApp
						isExternal
						_hover={{ transform: 'scale(1.1)' }} // Efecto al pasar el mouse
						transition="all 0.2s ease-in-out"
					>
						<Icon 
							as={FaWhatsapp}
							w="14" // Ancho del ícono
							h="14" // Altura del ícono
							color="green.400"
							bg="white"
							borderRadius="full"
							boxShadow="lg"
							p="2" // Espaciado interno
						/>
					</Link>
				</Box>
				<Flex
					flexDirection={'column'}
					backgroundColor={'white'}
					boxShadow={'lg'} 
					position={'fixed'} 
					height={ 30000 - cartContext.totalPrice > 0 ? '10vh' : '5vh'} // { 30000 - cartContext.totalPrice > 0 ? '10vh' : '5vh'}
					width={'17vh'} 
					borderRadius={'15'} 
					bottom={'4vh'} 
					right={'2vh'} 
					justify={'center'} 
					align={'center'} 
					zIndex="1000"
				>
					{
						30000 - cartContext.totalPrice > 0 ?
							<Box>
								<Flex flex={1} flexDirection={'column'} alignItems={'center'} paddingTop={'3'}>
									<Text fontFamily={'Work sans'} color={'#5F5F5F'} fontWeight={'bold'} textAlign={'center'} fontSize={15}>{translations?.['Minimum purchase']?.[language] || 'Mínimo de compra'}</Text>
									<Text fontFamily={'Work sans'} color={'#FD5756'} fontWeight={'bold'} fontSize={15}>$30000</Text>
								</Flex>
							</Box>
							: null
					}
					<Flex onClick={() => navigate('/cart')} flex={1} backgroundColor={'#5F993A'} width={'100%'} paddingInline={'4'} borderBottomRadius={'15'} 
						borderTopRadius={ 30000 - cartContext.totalPrice > 0 ? '0' : '15'} // { 30000 - cartContext.totalPrice > 0 ? '0' : '15'}
						flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
						<WhiteCartIcon boxSize={'2vh'} />
						<Text fontFamily={'Work sans'} color={'white'} fontWeight={'bold'}> ${cartContext.totalPrice}</Text>
					</Flex>
				</Flex>

				<Flex justify={'center'} align={'center'} >
					<Box marginBottom={'5%'} width={'95%'} >
						<Slider {...settings}  ref={(slider) => setSlider(slider)} >
							{
								mobileCarouselImages.map((image, index) => (
									<Box key={index}>
										<Image src={image} alt={`Imagen ${index}`} width={'100%'} borderRadius={15} />
									</Box>
								))}
						</Slider>
					</Box>

				</Flex>
				<Box w={'100%'} borderWidth={0} justifySelf={'center'} mt={'8%'} mb={'5%'}>
					<Box mb={'2vh'} alignSelf={'center'}>
						<Text textAlign={'center'} fontSize={28} fontWeight={'bold'}>{translations['What are you looking for?']?.[language]}</Text>
					</Box>
					<SimpleGrid spacing={2} justifyContent={'center'} alignContent={'center'} display={'flex'} flexWrap={'wrap'}>
						{
							allCompanies.map((company, index) => (
								currentCompany === company.id ?
									<Box cursor={'pointer'} borderRadius={22} p={'0.5'} bgGradient="linear(to-r, #EB5B4E, #724EE9 , #C18CAE )" key={index}>
										<Box bgColor={'#FFE5E8'} py={'1'} px={'5'} borderRadius={20}>
											<Text textAlign={'center'} color={'#25009C'} fontSize={18} fontWeight={'bold'}>{translations[company.businessName]?.[language]}</Text>
										</Box>
									</Box>
									:
									<Box opacity={(index == 5 || index == 9 || index == 10) ? 0.5 : 1 } cursor={(index == 5 || index == 9 || index == 10) ? '': 'pointer'} onClick={() => (index != 5 && index != 9 && index != 10) && updateCurrentCompany(company.id)} py={'1'} px={'5'} alignContent={'center'} borderRadius={24} borderWidth={1} borderColor={'#343434'} key={index}>
										<Text textAlign={'center'} fontSize={18}>{translations[company.businessName]?.[language]}</Text>
									</Box>
							))
						}
					</SimpleGrid>
				</Box>
				{/* <HStack justify={'center'} spacing={2} paddingX={'2%'} marginTop={'4%'}>
					<Image boxShadow={'lg'} src={language === 'spanish' ? mobileTechBannerES : mobileTechBannerCN} borderRadius={10} h={'29vh'} cursor={'pointer'} onClick={() => updateCurrentCompany(2)} />
					<Image boxShadow={'lg'} src={language === 'spanish' ? mobileFoodBannerES : mobileFoodBannerCN} borderRadius={10} h={'29vh'} cursor={'pointer'} onClick={() => updateCurrentCompany(3)} />
				</HStack> */}
				
				<VStack justify={'space-around'} spacing={3} paddingX={'2%'} marginTop={'3%'} zIndex={1}>
					{/* <Image boxShadow={'lg'} src={mobileSectionOriental} borderRadius={10} flex={0.2} h={'24vh'} cursor={'pointer'} onClick={() => updateCurrentCompany(3)} />
					<Image boxShadow={'lg'} src={mobileSectionBazar} borderRadius={10} flex={0.2} h={'24vh'} cursor={'pointer'} onClick={() => updateCurrentCompany(11)} /> */}
					{/* <Image boxShadow={'lg'} src={mobileSectionVinos} borderRadius={10} flex={0.4} h={'24vh'} cursor={'pointer'} onClick={() => updateCurrentCompany(2)} /> */}
				</VStack>

				{/* <HStack justify={'center'} spacing={2} mx={'2%'} marginTop={'3%'}>
					<Image src={mobile_bazar_banner1} borderRadius={10} flex={0.2} h={'9.6vh'} cursor={'pointer'} />
					<Image src={mobile_bazar_banner2} borderRadius={10} flex={0.2} h={'9.6vh'} cursor={'pointer'} />
				</HStack> */}
				<Box position={'relative'} alignItems={'center'}>
					{/* <Flex display={'flex'} position={'absolute'} borderWidth={'0px'} height={'.5vh'} top={'1vh'} right={'0vh'} left={'0vh'} justify={'center'} align={'center'}>
						<Text fontSize={'2vh'} textShadow="1px 1px #FFFF" fontWeight={'bold'}>推荐产品</Text>
					</Flex> */}

					<Image src={mobileCategoryTitleContainer} borderTopLeftRadius={10} borderTopRightRadius={10} marginTop={'3%'} h={'6vh'} width={'100%'} objectFit={'fill'} zIndex={1} />
					{/* <Filters boxSize={'6vh'} cursor={'pointer'} position={'absolute'} top={'0.5vh'} right={'1%'} onClick={() => setShowMobileFilters(!showMobileFilters)} /> */}
				</Box>

				{/* {
					showMobileFilters &&

					<Flex zIndex={10} position={'fixed'} top={'0%'} width={'100%'} height={'100%'} backgroundColor={showMobileFilters ? 'rgba(128, 128, 128, 0.5)' : 'white'} justify={'center'}>

						<Box backgroundColor={'white'} height={'fit-content'} width={'80%'} borderRadius={5} paddingX={'2%'} paddingY={'2%'} marginTop={'40%'}>

							<Flex justify={'flex-end'} >
								<CloseCross boxSize={'1.7vh'} onClick={() => setShowMobileFilters(!showMobileFilters)} />
							</Flex>

							<Box>
								<Text fontFamily={'Work sans'} color={'##8150E2'} fontWeight={'bold'} >筛选方式: </Text>

								{ expandFilters ?

									<Box backgroundColor={'white'} marginBottom={'5%'}>
										<Flex justify={'space-between'} px={'5%'} py={'5%'} align={'center'} borderBottom={'1px'} borderColor={'#C8C7C7'}>
											<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>产品分类</Text>
											<ArrowDown boxSize={'2vh'} onClick={() => setExpandFilters(!expandFilters)} cursor={'pointer'} />
										</Flex>
										<Flex backgroundColor={'#F5F3F3'} justify={'flex-start'} paddingLeft={'9%'} paddingY={'7%'}>
											<VStack spacing={4}> */}
				{/* {allCategories[currentCompany].map((checkbox) => (
                                                    <Checkbox
                                                        key={checkbox.category}
                                                        isChecked={checkbox.isChecked}
                                                        onChange={() => handleCheckboxChange(checkbox.id)}
                                                        colorScheme='blackAlpha'
                                                        fontFamily={'Work sans'}
                                                    >
                                                        {checkbox.label}
                                                    </Checkbox>
                                                ))} */}
				{/* </VStack>
										</Flex>
									</Box>

									:

									<Box backgroundColor={'white'} marginBottom={'5%'}>
										<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'}>
											<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>产品分类</Text>
											<ArrowUp boxSize={'2vh'} onClick={() => setExpandFilters(!expandFilters)} cursor={'pointer'} />
										</Flex>
									</Box>
								}

								{ expandFilters ?

									<Box backgroundColor={'white'}>
										<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'} borderBottom={'1px'} borderColor={'#C8C7C7'}>
											<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>批发价</Text>
											<ArrowDown boxSize={'2vh'} onClick={() => setExpandFilters(!expandFilters)} cursor={'pointer'} />
										</Flex>

										<Flex backgroundColor={'#F5F3F3'} justify={'flex-start'} paddingY={'7%'} align={'center'} paddingX={'5%'}>

											<Input
												placeholder={'Min'}
												fontFamily={'Work sans'}
												fontSize={'1.7vh'}
												width={'45%'}
												border={'1px'}
												borderColor={'#C0C0C0'}
												onChange={(e) => setMinPrice(parseInt(e.target.value))}
											/>

											<Box marginX={'5%'} border={'1px'} borderColor={'#C0C0C0'} height={0} width={'15%'} />

											<Input
												placeholder={'Max'}
												fontFamily={'Work sans'}
												fontSize={'1.7vh'}
												width={'45%'}
												border={'1px'}
												borderColor={'#C0C0C0'}
												onChange={(e) => setMaxPrice(parseInt(e.target.value))}
											/>

										</Flex>
									</Box>

									:

									<Box backgroundColor={'white'}>
										<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'}>
											<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>批发价</Text>
											<ArrowUp boxSize={'2vh'} onClick={() => setExpandFilters(!expandFilters)} cursor={'pointer'} />
										</Flex>
									</Box>
								}
							</Box>

							<Box
								onClick={() => filterProductsByModal()}
								backgroundColor={'#4C7D2C'}
								marginTop={'5%'}
								textAlign={'center'}
								borderRadius={7}
								paddingY={'.5vh'}
								width={'100%'}
								cursor={'pointer'}
							>
								<Text color={'white'} fontSize={'2vh'}>筛选</Text>
							</Box>
						</Box>
					</Flex>
				} */}
				{
					isExpanded ?
						<Box>
							<Flex align={'center'} justify={'space-between'} pl={'5%'} pb={'4%'} textAlign={'center'}>
								<Text fontSize={'2.5vh'} fontFamily={'Work Sans'} fontWeight={'bold'}> Categorías </Text>
								<ChevronUpRectangle boxSize={'5vh'} onClick={() => setIsExpanded(!isExpanded)} />
							</Flex>

							<Wrap align={'center'}>
								{ allCategories && allCategories[currentCompany].map((category, index) => (
									<WrapItem key={index} width={'30%'}>
										<Flex
											key={index}
											flexShrink={0}
											marginY={'3%'}
											paddingX={'3%'}
											justify={'center'}
											align={'center'}
											onClick={() => filterProductsByCategories(category)}
											cursor={'pointer'}
										>
											<Flex justify={'center'} align={'center'} >
												<Text 
													textAlign={'center'} 
													fontSize={'1.6vh'} 
													fontFamily={'Work Sans'} 
													width={'12vh'}
													border={'0px'}
													cursor={'pointer'}
													fontWeight={selectedCategory.id === category.id ? 'bold' : 'none'}
												>
													{category.category}
												</Text>
											</Flex>

										</Flex>
									</WrapItem>
								)
								)
								}
							</Wrap>
						</Box>

						:

						<Flex justify={'space-between'} align={'center'} position={'sticky'} top={'6%'} zIndex={1} backgroundColor={'white'}>

							<Flex 
								overflowX='auto'
								sx={{
									'&::-webkit-scrollbar': {
										width: 'auto',
										height: '10px',
										lineHeight: '8px',
										borderRadius: '10px',
										backgroundColor: '#F5F5F5'
									},
									'&::-webkit-scrollbar-thumb': {
										borderRadius: '8px',
										border: '1px solid #F5F5F5',
										backgroundColor: '#D5D5D5',
									}
								}}
								w="100%"
							>
								{ 
									allCategories[currentCompany] && allCategories[currentCompany].map((category, index) => (
										<Flex
											key={index}
											flexShrink={0}
											marginY={'3%'}
											paddingX={'5%'}
											borderRight={index === category.id ? '0px' : '1px'}
											borderColor={'#C5C5C5'}
											onClick={() => filterProductsByCategories(category)}
											cursor={'pointer'}
										>
											<Flex justify={'center'} align={'center'} >
												<Text textAlign={'center'} fontSize={'1.5vh'} fontFamily={'Work Sans'} fontWeight={selectedCategory.id === category.id ? 'bold' : 'none'} width={'10vh'}>{category.category}</Text>
											</Flex>

										</Flex>
									))}
							</Flex>
							<ChevronDownRectangle boxSize={'5vh'} onClick={() => setIsExpanded(!isExpanded)} />
						</Flex>
				}
				
				
				<Box ref={boxRef} zIndex={0} >
					{
						isLoading ?
							<Flex justify={'center'} mt='10%' height={ products.length === 0 ? '300px' : 'auto' } paddingBottom={'5%'}>
								<Spinner size={'xl'} marginTop={'10%'} />
							</Flex>
							:
							<ListOfProducts products={products} originalProducts={originalProducts} isHome={true} lastProductElementRef={lastProductElementRef} />
					}
					{
						isLoadingNewPage &&
						<Flex justifyContent={'center'} mt='10%'>
							<Spinner size={'xl'} />
						</Flex>
					}

				</Box>
			</Box>
		);
	};


	const desktopFront = () => {
		return (
			<Box pt={20}>
				{/* <Center>
					<Image src={headerNotification} pb={'1%'} />
				</Center> */}
				<Flex justifyContent={'center'} >
					{/* <Box flex={0.23} bgColor={'white'} h={'50%'} borderRadius={'0.5vh'} mr={'3%'} boxShadow={'md'}>
						<CompanyCategoriesHover
							imageRoute={section1cropped}
							companyInfo={{ name: TICH_COMPANIES[3], id: 3 }}
							companyCategories={allCategories[3]}
							setCurrentCompany={updateCurrentCompany}
							updated={allCategories[3] !== undefined && allCategories[3].length !== 0}
							handleCategoryClicked={filterProductsByCategories}
						/>

						<CompanyCategoriesHover
							imageRoute={section3cropped}
							companyInfo={{ name: TICH_COMPANIES[11], id: 11 }}
							companyCategories={allCategories[11]}
							setCurrentCompany={updateCurrentCompany}
							updated={allCategories[11] !== undefined && allCategories[11].length !== 0}
							handleCategoryClicked={filterProductsByCategories}
						/>

						<CompanyCategoriesHover
							imageRoute={section2cropped}
							companyInfo={{ name: TICH_COMPANIES[2], id: 2 }}
							companyCategories={allCategories[2]}
							setCurrentCompany={updateCurrentCompany}
							updated={allCategories[2] !== undefined && allCategories[2].length !== 0}
							handleCategoryClicked={filterProductsByCategories}
						/>
					</Box> */}
					<Carousel flex={0.85} />
				</Flex>
				<Box justifyContent={'center'} alignItems={'center'} mt={'8%'} mb={'5%'}>
					<Box mb={'2vh'} alignSelf={'center'}>
						<Text textAlign={'center'} fontSize={30} fontWeight={'bold'}>{translations['What are you looking for?']?.[language]}</Text>
					</Box>
					<Box
						// height="100vh"
						display="flex"
						justifyContent="center"
						alignItems="center"
						// bg="gray.50" // Fondo opcional para resaltar el diseño
					>
						<SimpleGrid width={'85%'} spacing={3} justifyContent={'center'} alignContent={'center'} display={'flex'} flexWrap={'wrap'}>
							{
								allCompanies.map((company, index) => (
									currentCompany === company.id ?
										<Box cursor={'pointer'} borderRadius={22} p={'0.5'} bgGradient="linear(to-r, #EB5B4E, #724EE9 , #C18CAE )" key={index}>
											<Box bgColor={'#FFE5E8'} py={'1'} px={'8'} borderRadius={20}>
												<Text textAlign={'center'} color={'#25009C'} fontSize={24} fontWeight={'bold'}>{translations[company.businessName]?.[language]}</Text>
											</Box>
										</Box>
										:
										<Box opacity={(index == 5 || index == 9 || index == 10) ? 0.5 : 1 } cursor={(index == 5 || index == 9 || index == 10) ? '': 'pointer'} onClick={() => (index != 5 && index != 9 && index != 10) && updateCurrentCompany(company.id)} py={'1'} px={'8'} alignContent={'center'} borderRadius={24} borderWidth={1} borderColor={'#343434'} key={index}>
											<Text textAlign={'center'} fontSize={24}>{translations[company.businessName]?.[language]}</Text>
										</Box>
								))
							}
						</SimpleGrid>
					</Box>
				</Box>

				{/* PROMOCIONES */} {/* navigate('/search', {state: {companyId: '2', searchTextParam: {searchText:''}}}) */}
				{/* <Box display={'flex'} justifyContent={'center'} marginTop={'3%'}>
					<Flex width={'85%'} justify={'space-between'}>
						<Box borderRadius={10} width={'48%'}>
							<Image boxShadow={'md'} src={language==='spanish' ? desktopTechBannerES : desktopTechBannerCN} borderRadius={10} cursor={'pointer'} onClick={() => updateCurrentCompany(2)}/> 
						</Box>
						<Box borderRadius={10} width={'48%'}>
							<Image boxShadow={'md'} src={language==='spanish' ? desktopFoodBannerES : desktopFoodBannerCN} borderRadius={10} cursor={'pointer'} onClick={() => updateCurrentCompany(3)}/>
						</Box> */}
					
				{/* <Box width={'40%'} marginLeft={'1%'}>
							<Flex height={'48%'} borderRadius={10}>
								<Image boxShadow={'md'} src={banner_promo2} borderRadius={10} cursor={'pointer'} onClick={() => navigate('/search', {state: {group: '4', searchTextParam: {searchText:''}}})}/>
							</Flex>
							<Flex height={'48%'} marginTop={'4%'} borderRadius={10}>
								<Image boxShadow={'md'} src={banner_promo3} borderRadius={10} cursor={'pointer'} onClick={() => navigate('/search', {state: {group: '2', searchTextParam: {searchText:''}}})}/>
							</Flex>
						</Box> */}
				{/* <Box width={'45%'} marginLeft={'1%'}>
							<Flex height={'48%'} borderRadius={10}>
								<Image src={youMaylikeBanner} cursor={'pointer'} borderRadius={10}/>
							</Flex>
							<Flex height={'48%'} marginTop={'4%'} borderRadius={10}>
								<Image src={mostSalesBanner} cursor={'pointer'} borderRadius={10}/>
							</Flex>
						</Box> */}
				{/* <Box width={'30%'} marginInline={'1%'}>
							<Flex backgroundColor={'white'} height={'48%'} borderRadius={'2%'} boxShadow={'md'}>
								<Box width={'70%'} textAlign={'center'} paddingTop={'20%'}>
									<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2vh'}>生活家電生</Text>
									<Text fontFamily={'Work Sans'} fontSize={'1.6vh'}>生活家電生</Text>
								</Box>
								<Box marginTop={'5%'} width={'100%'}>
									<Image src={suitcase} width={'70%'} height={'auto'} />
								</Box>
							</Flex>
							<Flex backgroundColor={'white'} height={'48%'} marginTop={'5%'} borderRadius={'2%'} boxShadow={'md'}>
								<Box width={'70%'} textAlign={'center'} paddingTop={'20%'}>
									<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2vh'}>生活家電生</Text>
									<Text fontFamily={'Work Sans'} fontSize={'1.6vh'}>生活家電生</Text>
								</Box>
								<Box marginTop={'5%'} width={'100%'}>
									<Image src={suitcase} width={'70%'} height={'auto'} />
								</Box>
							</Flex>
						</Box> */}
				{/* <Box backgroundColor={'#E8A765'} textAlign={'center'} padding={'2%'} borderRadius={'2%'} paddingY={'5%'} boxShadow={'md'} width={'20%'} height={'auto'}>
							<Text fontFamily={'Work Sans'} color={'#6F4011'} fontWeight={'bold'} fontSize={'2.5vh'}>生活家電生</Text>
							<Text fontFamily={'Work Sans'} fontSize={'2vh'}>生活家電生</Text>
							<Box marginTop={'10%'} width={'100%'}>
								<Image src={suitcase} width={'100%'} height={'auto'} />
							</Box>
						</Box> */}
				{/* </Flex>
				</Box> */}

				<Box display={'flex'} justifyContent={'center'} marginTop={'3%'}>
					<Image src={categoryTitleContainer} width={'85%'} h={'10vh'} position={'absolute'} zIndex={0} borderTopRadius={10}/>

					<Box zIndex={2} width={'85%'} textAlign={'center'} marginTop={'2.5vh'}>
						{/* <Text fontWeight={'bold'} fontSize={'3vh'} textShadow="1px 1px #FFFF" > 推荐产品 </Text> */}
						<Box backgroundColor={'white'} marginTop={'3vh'}>
							<Flex
								overflowX='auto'
								sx={{
									'&::-webkit-scrollbar': {
										width: 'auto',
										height: '10px',
										lineHeight: '8px',
										borderRadius: '10px',
										backgroundColor: '#F5F5F5',
									},
									'&::-webkit-scrollbar-thumb': {
										borderRadius: '8px',
										border: '1px solid #F5F5F5',
										backgroundColor: '#D5D5D5',
									}
								}}>
								{
									allCategories[currentCompany] && allCategories[currentCompany].map((category, index) => (
										<Box
											key={index}
											width={'30%'}
											padding={'1%'}
											borderRight={index === category.id ? '0px' : '1px'}
											borderRightColor={'#D5D5D5'}
											onClick={() => filterProductsByCategories(category)}
											cursor={'pointer'}
										>
											<Flex direction='column' align={'center'}>
												<Text textAlign={'center'} fontSize={'1.5vh'} fontFamily={'Work Sans'} fontWeight={selectedCategory.id === category.id ? 'bold' : 'none'} width={'20vh'}>{category.category}</Text>
											</Flex>
										</Box>
									))
								}
							</Flex>
							{  isLoading &&
								<Flex justifyContent={'center'} py='5%' backgroundColor='white' borderBottomRadius={5} paddingBottom={ products.length === 0 ? '30%' : '0%'}>
									<Spinner size='xl' marginTop={'5%'}/>
								</Flex>

							}
						</Box>
					</Box>
				</Box>
				<Box ref={boxRef}>
					{ !isLoading && <ListOfProducts products={products} originalProducts={originalProducts} isHome={true} lastProductElementRef={lastProductElementRef} /> }
					{
						isLoadingNewPage &&
							<Flex justifyContent={'center'} mt='10%'>
								<Spinner size={'xl'} />
							</Flex>
					}
				</Box>
				
			</Box>
		);
	};

	return (
		<Box as="main" backgroundColor={'#F5F3F3'} minHeight={'80vh'}>
			<NavBar filterByTextSearch={filterByTextSearch} isHome={true} />
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
}


// async function handleScroll() {
//     const total_height = document.body.offsetHeight;
//     console.log(currentPage);
//     // console.log((window.innerHeight + Math.round(document.documentElement.scrollTop)));


//     if ((window.innerHeight + Math.round(document.documentElement.scrollTop)) >= (total_height - 1) && !isLoading) {
//         console.log('holis final de la ppage', currentPage, "Loading", isLoading);
//         // if (!isLoading) {
//         console.log('currentPage nueva:', currentPage + 1);
//         // await loadProducts(currentPage + 1)
//         setCurrentPage(currentPage + 1)
//         // }
//     }
// }
// useEffect(() => {

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//         window.removeEventListener('scroll', handleScroll);
//     };
// }, []);

// Por ahora no se usa, se hardcodean las categories
// const loadCategories = async () => {
//     const tichCompanyIds = Object.keys(TICH_COMPANIES);
//     let allCategoriesAux: AllCategories = [];
//     tichCompanyIds.forEach(async (currentCompanyId: string) => {
//         const companyIdParsed = parseInt(currentCompanyId, 10);
//         const categoriesAux: Category[] = await getCategories({ companyId: companyIdParsed });
//         allCategoriesAux[companyIdParsed] = categoriesAux
//         allCategoriesAux[companyIdParsed].unshift({ category: 'Todos' })
//     })
//     setAllCategories(allCategoriesAux)
//     setSelectedCategory(categoriesMenu[0]);
//     // Para mobile
//     // setCategories(categoriesAux)

//     // let auxCheckBox: CategoryCheckBox[] = []
//     // let id = 0

//     // categoriesAux.map((category: Category) => {
//     //     auxCheckBox.push({ id: id++, label: category.category, isChecked: false })
//     //     return null
//     // })

//     // setCategoriesCheckBox(auxCheckBox)
// }

// const handleCheckboxChange = (checkboxId: number) => {
//     const updatedCheckboxes = categoriesCheckBox.map(checkbox => {
//         if (checkbox.id === checkboxId) {
//             return {
//                 ...checkbox,
//                 isChecked: !checkbox.isChecked,
//             };
//         }
//         return checkbox;
//     });

//     setCategoriesCheckBox(updatedCheckboxes);
// };


{/*  MOBILE CATEGORIES
{
	isExpanded ?

		<Box>
			<Flex align={'center'} justify={'space-between'} textAlign={'center'}>
				<Flex width={'90%'} align={'center'} justify={'center'}>
					<Text fontSize={'2vh'} fontFamily={'Work Sans'} fontWeight={'bold'}> 产品分类</Text>
				</Flex>
				<ChevronUpRectangle boxSize={'5vh'} onClick={() => setIsExpanded(!isExpanded)} />
			</Flex>

			<Wrap justify={'center'}>
				{//allCategories[currentCompany] && allCategories[currentCompany].map((category, index) => (
					categoriesMenu && categoriesMenu.map((category, index) => (
						<WrapItem key={index} width={'30%'}>
							<Flex
								key={index}
								flexShrink={0}
								marginY={'3%'}
								paddingX={'3%'}
								justify={'center'}
								align={'center'}
								onClick={() => filterProductsByCategories(category)}
							>
								<Flex justify={'center'} align={'center'} >
									{category.image === '' ?
										(category.imageTitle !== '') &&
										<Flex backgroundColor={'#E1F0D7'} width={'4.5vh'} height={'4.5vh'} borderRadius={'10%'} marginRight={'7%'} justify={'center'} align={'center'}>
											<Text color={'#4C7D2C'} fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={'1.7vh'}>{category.imageTitle}</Text>
										</Flex>
										:
										<Image src={category.image} width={'4.5vh'} height={'4.5vh'} marginRight={'7%'} borderRadius={'10%'} />
									}
									<Text textAlign={'center'} fontSize={'1.4vh'} fontFamily={'Work Sans'} fontWeight={selectedCategory.id === category.id ? 'bold' : 'none'} width={'10vh'}>{category.category}</Text>
								</Flex>

							</Flex>
						</WrapItem>
					))
				}
			</Wrap>
		</Box>

		:

		<Flex justify={'space-between'} align={'center'} position={isSticky ? 'sticky' : 'static'} top={isSticky ? '7%' : ''} zIndex={1} backgroundColor={'white'}>

			<Flex overflowX="scroll" w="100%">
				{//allCategories[currentCompany] && allCategories[currentCompany].map((category, index) => (
					categoriesMenu && categoriesMenu.map((category, index) => (
						<Flex
							key={index}
							flexShrink={0}
							marginY={'3%'}
							paddingX={'3%'}
							borderRight={index === category.id ? '0px' : '1px'}
							borderColor={'#C5C5C5'}
							onClick={() => filterProductsByCategories(category)}
						>
							<Flex justify={'center'} align={'center'} >
								{category.image === '' ?
									(category.imageTitle !== '') &&
									<Flex backgroundColor={'#E1F0D7'} width={'4.5vh'} height={'4.5vh'} borderRadius={'10%'} marginRight={'7%'} justify={'center'} align={'center'}>
										<Text color={'#4C7D2C'} fontFamily={'Work Sans'} fontWeight={'bold'} fontSize={'1.7vh'}>{category.imageTitle}</Text>
									</Flex>
									:
									<Image src={category.image} width={'4.5vh'} height={'4.5vh'} marginRight={'7%'} borderRadius={'10%'} />
								}
								<Text textAlign={'center'} fontSize={'1.5vh'} fontFamily={'Work Sans'} fontWeight={selectedCategory.id === category.id ? 'bold' : 'none'} width={'10vh'}>{category.category}</Text>
							</Flex>

						</Flex>
					))}
			</Flex>
			<ChevronDownRectangle boxSize={'5vh'} onClick={() => setIsExpanded(!isExpanded)} />
		</Flex>
} */}